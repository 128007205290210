module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<ul>\n  <li ng-repeat="layer in ::$ctrl.layer.Layer" ng-show="layer._visible">\n    <div class="gmf-wmscapabilitylayertreenode-header">\n      <span\n        class="gmf-wmscapabilitylayertreenode-popover-container"\n        ng-if="::(layer.Name !== undefined || layer.Abstract !== undefined)"\n        ngeo-popover\n        ngeo-popover-dismiss=".content"\n        ngeo-popover-placement="left"\n      >\n        <span ngeo-popover-anchor class="fa fa-cog gmf-wmscapabilitylayertreenode-actions"> </span>\n\n        <div class="gmf-wmscapabilitylayertreenode-popover-content" ngeo-popover-content>\n          <ul>\n            <li ng-if="::(layer.Name !== undefined)">\n              <i class="fa fa-plus fa-fw"></i>\n              <a href="" ng-click="$ctrl.createAndAddDataSource(layer); popoverCtrl.dismissPopover()">\n                <span translate>Add layer</span>\n              </a>\n            </li>\n\n            <li ng-if="::(layer.Abstract !== undefined)">\n              <i class="fa fa-th-list fa-fw"></i>\n              <a\n                aria-expanded="false"\n                data-toggle="collapse"\n                href="#gmf-wmscapabilitylayertreenode-description-{{::$ctrl.getUid(layer)}}"\n                ng-click="popoverCtrl.dismissPopover()"\n              >\n                <span translate>Open/Close description</span>\n              </a>\n            </li>\n          </ul>\n        </div>\n      </span>\n\n      <a\n        aria-expanded="{{ layer._expanded }}"\n        class="fa gmf-wmscapabilitylayertreenode-expand-node fa-fw"\n        data-toggle="collapse"\n        href="#gmf-wmscapabilitylayertreenode-children-{{::$ctrl.getUid(layer)}}"\n        ng-if="::(layer.Layer !== undefined && layer.Layer.length)"\n      ></a>\n\n      <span\n        ng-if="::(layer.Layer !== undefined && layer.Layer.length)"\n        class="fa fa-fw gmf-wmscapabilitylayertreenode-group"\n        >' +
((__t = (require('gmf/icons/layers.svg?viewbox&height=1em'))) == null ? '' : __t) +
'</span\n      >\n\n      <a\n        class="fa fa-circle-thin gmf-wmscapabilitylayertreenode-no-icon fa-fw"\n        ng-if="::(layer.Layer === undefined || layer.Layer.length === 0)"\n      ></a>\n\n      <span class="gmf-wmscapabilitylayertreenode-title" ng-if="!layer._searchMatch"\n        >{{ ::layer.Title }}</span\n      >\n      <span class="gmf-wmscapabilitylayertreenode-title" ng-if="layer._searchMatch"\n        >{{ layer._searchPrefix }}<span class="highlight">{{ layer._searchMatch }}</span>{{\n        layer._searchSuffix }}</span\n      >\n\n      <div\n        class="collapse gmf-wmscapabilitylayertreenode-description"\n        id="gmf-wmscapabilitylayertreenode-description-{{::$ctrl.getUid(layer)}}"\n        ng-if="::(layer.Abstract !== undefined)"\n      >\n        <a\n          aria-expanded="false"\n          class="gmf-wmscapabilitylayertreenode-description-toggle"\n          data-toggle="collapse"\n          href="#gmf-wmscapabilitylayertreenode-description-{{::$ctrl.getUid(layer)}}"\n        >\n          <span translate>Hide description</span>\n        </a>\n        <span class="gmf-wmscapabilitylayertreenode-description-content">{{ ::layer.Abstract }}</span>\n      </div>\n    </div>\n\n    <gmf-wmscapabilitylayertreenode\n      class="collapse"\n      ng-class="layer._expanded ? \'show\' : \'\'"\n      id="gmf-wmscapabilitylayertreenode-children-{{::$ctrl.getUid(layer)}}"\n      capabilities="::$ctrl.capabilities"\n      layer="::layer"\n      url="::$ctrl.url"\n      ng-if="::(layer.Layer !== undefined)"\n    >\n    </gmf-wmscapabilitylayertreenode>\n  </li>\n</ul>\n';

}
return __p
}