module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="gmf-profile-container panel" ng-show="ctrl.active">\n  <div class="spinner-profile" ng-show="ctrl.isLoading">\n    <i class="fa fa-spin"> ' +
((__t = (require('gmf/icons/spinner.svg?viewbox&height=3rem'))) == null ? '' : __t) +
' </i>\n  </div>\n\n  <div\n    class="ngeo-profile"\n    ng-if="!ctrl.isErrored && !ctrl.isLoading"\n    ngeo-profile="ctrl.profileData"\n    ngeo-profile-highlight="ctrl.profileHighlight"\n    ngeo-profile-options="::ctrl.profileOptions"\n  ></div>\n\n  <ul class="gmf-profile-legend" ng-if="!ctrl.isErrored && !ctrl.isLoading">\n    <li ng-repeat="name in ::ctrl.getLayersNames()">\n      <i class="fa fa-minus" ng-style="ctrl.getStyle(name)"></i>\n      {{name | translate}}\n      <span ng-if="ctrl.currentPoint.elevations[name] != null">\n        {{ctrl.currentPoint.elevations[name]}}&nbsp;{{ctrl.currentPoint.yUnits}}\n      </span>\n    </li>\n  </ul>\n\n  <div class="gmf-profile-export btn-group dropup" ng-show="!ctrl.isErrored && !ctrl.isLoading">\n    <a\n      class="dropdown-toggle"\n      href=""\n      ng-show="ctrl.profileData.length !== 0"\n      data-toggle="dropdown"\n      aria-expanded="false"\n    >\n      {{\'Export\' | translate}}\n    </a>\n\n    <ul class="dropdown-menu dropdown-menu-right" role="menu">\n      <li>\n        <a href="" ng-click="::ctrl.downloadCsv()">\n          <i class="fa fa-table"></i>&nbsp;{{\'Download CSV\' | translate}}</a\n        >\n      </li>\n    </ul>\n  </div>\n\n  <div class="gmf-profile-error" ng-show="ctrl.isErrored">\n    <p>{{\'The profile service does not respond, please try later.\' | translate}}</p>\n  </div>\n  <div class="close" ng-click="ctrl.line = null">&times;</div>\n</div>\n';

}
return __p
}