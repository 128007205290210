module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div\n  class="text-center btn-group dropup"\n  gmf-elevation\n  gmf-elevation-active="ctrl.active"\n  gmf-elevation-elevation="ctrl.elevationValue"\n  gmf-elevation-loading="ctrl.elevationLoading"\n  gmf-elevation-layer="ctrl.selectedElevationLayer"\n  gmf-elevation-layersconfig="::ctrl.options.layersconfig"\n  gmf-elevation-map="::ctrl.map"\n  ng-show="::ctrl.show"\n>\n  <a\n    class="btn btn-default"\n    aria-expanded="false"\n    ng-class="::{\'dropdown-toggle\': ctrl.options.layers.length > 1}"\n    ng-attr-data-toggle="{{::(ctrl.options.layers.length > 1) ? \'dropdown\' : \'\'}}"\n  >\n    <span class="gmf-elevationwidget-value">\n      {{ctrl.elevationValue}}\n      <span ng-show="ctrl.elevationLoading" class="fa fa-spin custom-spinner-generic">\n        ' +
((__t = (require('gmf/icons/spinner.svg?viewbox&height=1rem'))) == null ? '' : __t) +
'\n      </span>\n      <span ng-show="!ctrl.elevationValue && !ctrl.elevationLoading" translate>Raster</span> </span\n    ><span class="caret" ng-if="::ctrl.options.layers.length > 1"></span>\n  </a>\n  <ul class="dropdown-menu dropdown-menu-right" role="menu" ng-if="::ctrl.options.layers.length > 1">\n    <li class="dropdown-header" translate>Data source</li>\n    <li ng-repeat="elevationItem in ::ctrl.options.layers">\n      <a href ng-click="ctrl.selectedElevationLayer = elevationItem">\n        <span class="fa fa-fw" ng-class="{\'fa-check\': ctrl.selectedElevationLayer === elevationItem}"></span>\n        {{elevationItem | translate}}\n      </a>\n    </li>\n  </ul>\n</div>\n';

}
return __p
}